import util from "./util";

import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
	created() {
		this.categories = this.itemCategoryList;
		this.detailCategories = this.itemCategoryList[0]?.child;
	},
	mounted() {

	},
	data() {
		return {
			categories: [],
			detailCategories: [],
		}
	},
	computed: {
		...mapState({
			itemCategoryList: state => state.itemCategory.itemCategoryList,
			Country: state => state.country.Country,
		})
	},
	methods: {
		setCategory(value) {
			this.parentChange('category', value);
			this.hashtag = [];
			this.hashData = '';
			this.hashInput = '';
			let categoryIndex = this.categories.findIndex(category => category.node_id === value);
			if (!util.isEmpty(this.categories[categoryIndex].child)) {
				this.detailCategories = this.categories[categoryIndex].child;
			} else {
				this.detailCategories = [];
			}
		},
		returnCategoryName(value) {
			let category = this.categories.filter(category => category.node_id === value);
			if (this.Country === 'ko') {
				return !util.isEmpty(category) ? category[0].NAME : ''
			} else if (this.Country === 'en') {
				return !util.isEmpty(category) ? category[0].NAME_ENG : ''

			}
		},

	}
}
