<template>
    <div class="item_list_entry_wrap">
        <ul class="entry_list_wrap">
            <li class="list_item" v-for="(entryItem,index) in itemData" :key="`entry-item-${index}`"
            @click="selectEntryItem(entryItem.i_idx,entryItem.i_name)">
                <div class="thumbnail"
                     :style="`background-image : url(${setFitWidthImg(entryItem.ItemFile.Item,278)})`">
                    <div class="card_category cc_category">{{ returnCategoryName(entryItem.ic_idx) }}</div>
                </div>
                <div class="card_detail">
                    <div class="card_title">{{ entryItem.i_name }}</div>
                </div>
            </li>

        </ul>

    </div>

</template>

<script>
import itemCategoryMixin from "@/mixins/itemCategoryMixin";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListEntryLayout",
    mixins: [itemCategoryMixin, imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {
            type: Array,
            default: () => {
                return []
            }
        },

    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnImage(image, width = 32, height = 32, f='png') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, f)}`
            }
            return ''
        },
        selectEntryItem(idx,name){
            EventBus.$emit('selectEntryItem',idx,name)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
